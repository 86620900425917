import React from "react";
import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Description } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";

import Header from "../utils/Header";
import Footer from "../utils/Footer";
import JobBody from "./JobComponents";

const JobDescriptionText = (
  <>
    <p>
      丸紅は、デジタル技術の発展を勝機と捉え、2018年に専門組織「デジタル・イノベーション部」を設立し、会社の変革と新規ビジネス創出に取り組んできました。2023年8月、デジタル・イノベーション部の機能をより拡大強化すべく、「Digital
      Experts株式会社」が設立されました。デジタル・イノベーション部およびDigital
      Expertsでは、総合商社が取り扱う幅広い領域での問題に対処するために、ソフトウェアエンジニアリングを中心に、アルゴリズムや各種AI技術に関する専門性を駆使して、幅広い視野からの問題解決を目指しています。総合商社本社での勤務（リモート可）のため、ビジネスの現場部署の社員と直接、密にコミュニケーションを取って業務に取り組むことが可能です。
    </p>
    【直近の取組テーマ例】
    <ul>
      <li>統計モデルを用いた、商品の需要予測ソリューションの開発</li>
      <li>画像処理を用いた、工場における自動化ソリューションの開発</li>
      <li>数理最適化を用いた、効率的な車両運用プログラムの開発</li>
      <li>車両の稼働ログデータの分析・可視化</li>
      <li>自治体向け車両管理Webアプリケーションの開発</li>
    </ul>
    【使用技術スタック例】
    <ul>
      <li>言語: Python, Javascript, Typescript, Go, C++, SQL</li>
      <li>
        ライブラリ: React, Next.js, AWS Amplify, langchain, Python-MIP,
        OR-Tools, OpenCV
      </li>
      <li>
        アプリケーションインフラ: AWS Lambda, AWS Fargate, Amazon S3, AWS
        AppSync, Amazon EC2, Amazon Aurora, Amazon DynamoDB
      </li>
      <li>IaaC: AWS CDK, Terraform, AWS CloudFormation</li>
      <li>
        分析基盤: Amazon Redshift, BigQuery, Amazon Athena, Amazon SageMaker,
        Power BI
      </li>
      <li>
        LLM: Amazon Bedrock API, Azure OpenAI Service, OpenAI API, Google Gemini
        API
      </li>
      <li>デザインツール: Figma</li>
      <li>その他開発ツール: Docker, Git</li>
    </ul>
  </>
);

const transportations = [
  "地下鉄「竹橋駅」3a出口より徒歩1分（東京メトロ東西線）",
  "地下鉄「大手町駅」C2b出口より徒歩6分（東京メトロ千代田線）",
  "地下鉄「神保町駅」A9出口より徒歩7分（東京メトロ半蔵門線・都営地下鉄新宿線・三田線）",
];

function createData(name, data) {
  return { name, data };
}

const tableRows = [
  createData("給与", "時給 2500円 〜 5000円 (能力・経験に応じる)"),
  createData("勤務期間/時間", "下記のいずれか\n1~2ヶ月: 週24時間\n2~3ヶ月: 週18時間\n3ヶ月~: 週15時間(応相談)"),
  createData("勤務地", "東京都 千代田区大手町一丁目4番2号 リモート勤務可"),
  createData("アクセス", transportations.join("\n")),
  createData("保険", "傷害総合保険"),
  createData("その他", "フリースナック、LT 会 (2週間に1回)"),
];

const essentialCriteria = (
  <>
    <ul>
      <li> 情報工学・コンピューターサイエンス領域の広範かつ卓越した知見 </li>
      <li> ソフトウェア・機械学習・数理最適化等のデジタル技術に関する高度な専門性 </li>
      <li> 総合商社の事業変革・新事業創出への強い熱意 </li>
      <li> ビジネスレベル以上の日本語力 </li>
    </ul>
  </>
);

const desirableCriteria = (
  <>
    <ul>
      <li> デジタル技術を活用したプロジェクト立上げの実務経験 </li>
      <li> 学術論文執筆・学会発表経験 </li>
      <li> 各種クラウドインフラ上でのWebサービス開発・運用経験 </li>
      <li> ビジネスレベル以上の英語力 </li>
    </ul>
  </>
);

const formLink =
  "https://docs.google.com/forms/d/e/1FAIpQLSftiuZVZg4Za0f6pt73bDbPX9iryyI44I2ISeVy69WcVE6yRg/viewform";

const processInfo = [
  {
    title: "1. 書類選考",
    icon: <Description color="primary" sx={{ fontSize: 100, py: 2 }} />,
    description: "100 文字程度の\nアピールポイントと\n履歴書を提出",
  },
  {
    title: "2. 面接",
    icon: <PersonIcon color="primary" sx={{ fontSize: 100, py: 2 }} />,
    description: "原則 1 ~ 2 回\nオンラインで実施",
  },
  {
    title: "3. 採用",
    icon: <MeetingRoomIcon color="primary" sx={{ fontSize: 100, py: 2 }} />,
    description: "",
  },
];

const Intern = () => {
  return (
    <Box sx={{ bgcolor: grey[100] }}>
      <Header />
      <JobBody
        contractType="インターン生"
        occupation="ソフトウェアエンジニア"
        formLink={formLink}
        JobDescriptionText={JobDescriptionText}
        essentialCriteria={essentialCriteria}
        desirableCriteria={desirableCriteria}
        tableRows={tableRows}
        processInfo={processInfo}
      />
      <Footer />
    </Box>
  );
};

export default Intern;
