import React from "react";
import { Box, Container, Typography, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import Carousel from "react-material-ui-carousel";

const StyledCarousel = styled(Carousel)(({ theme }) => ({
  maxWidth: 800,
  margin: "auto",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius, // デフォルトの角丸を適用
  overflow: "hidden", // 角丸を確実に適用するため
}));

const StyledImage = styled("img")(({ theme }) => ({
  height: 400,
  display: "block",
  maxWidth: 800,
  width: "100%",
  objectFit: "cover",
  borderRadius: theme.shape.borderRadius, // 画像自体にも角丸を適用
}));

const StyledCaption = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(2),
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  color: "white",
  borderBottomLeftRadius: theme.shape.borderRadius, // 下部の角丸を適用
  borderBottomRightRadius: theme.shape.borderRadius,
}));

const images = [
  {
    label: "オフィス",
    imgPath: "./images/sofar.webp",
    description:
      "オフィスにはソファやロングテーブルなど様々な席があり好きな場所に座れます",
  },
  {
    label: "オフィス(窓際)",
    imgPath: "./images/window.webp",
    description: "オフィスの窓からは皇居一体が眺望できます",
  },
  {
    label: "LT 会",
    imgPath: "./images/lt.webp",
    description: "2 週間に 1 回 LT 会を開催しています",
  },
  {
    label: "ポルトガル出張時",
    imgPath: "./images/in_portugal.webp",
    description: "Digital Experts 社員がポルトガルに出張した際の写真です",
  },
  {
    label: "Digital Experts 社員",
    imgPath: "./images/experts.webp",
    description: "Digital Experts 社員の写真です",
  },
  {
    label: "エントランス",
    imgPath: "./images/lobby.webp",
    description: "オフィスのエントランスです",
  },

]

function ImageSlider() {
  // 画像をスライドショーで表示
  return (
    <StyledCarousel
      animation="slide"
      navButtonsAlwaysVisible
      autoPlay={false}
      cycleNavigation={true}
      navButtonsProps={{
        style: {
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          borderRadius: 0,
        },
      }}
    >
      {images.map((item, index) => (
        <StyledPaper key={index} elevation={3}>
          <StyledImage src={item.imgPath} alt={item.label} />
          <StyledCaption>
            <Typography variant="h6">{item.label}</Typography>
            <Typography variant="body2" color="white" fontWeight={400}>
              {item.description}
            </Typography>
          </StyledCaption>
        </StyledPaper>
      ))}
    </StyledCarousel>
  );
}

const WorkStyles = () => {
  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Typography
          component="h2"
          color="primary"
          sx={{ fontSize: "2rem", fontWeight: 700, letterSpacing: "0.1em" }}
        >
          GALLERY
        </Typography>
        <Typography component="h2" color="primary" sx={{ fontSize: "1rem", letterSpacing: "0.05em" }}>
          ギャラリー
        </Typography>
      </Box>
      <Container sx={{ py: 8 }}>
        <ImageSlider />
      </Container>
    </>
  );
};

export default WorkStyles;
